<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>

        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                src="@/assets/images/pages/financelogo.png"
                alt="login"
                class="mx-auto"
                width="70%"
              />
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">

              <div class="p-8 login-tabs-container">
                                                <i18n/>

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">{{ $t("Login") }}</h4>
                  <p>{{ $t("Welcome back, please login to your account.") }}</p>
                </div>

                <div>
                  <br />
                  <vs-input
                    name="email"
                    icon-no-border
                    icon="icon icon-user"
                    icon-pack="feather"
                    :label-placeholder="$t('Email')"
                    v-model="email"
                    class="w-full"
                  />
                  <vs-alert
                    color="danger"
                    v-show="ValidationErrors.email"
                    :title="$t('Email')"
                    active="true"
                  >
                    {{ $t(ValidationErrors.email) }}
                    <br />
                  </vs-alert>
                  <br />
                  <vs-input
                    type="password"
                    name="password"
                    icon-no-border
                    icon="icon icon-lock"
                    icon-pack="feather"
                    :label-placeholder="$t('Password')"
                    v-model="password"
                    class="w-full mt-6"
                  />
                  <br />
                  <vs-alert
                    color="danger"
                    v-show="ValidationErrors.password"
                    :title="$t('Password')"
                    active="true"
                  >
                    {{ $t(ValidationErrors.password) }}
                    <br />
                  </vs-alert>
                  <br />
                  <!-- <div class="flex flex-wrap justify-between my-5">
                    <vs-checkbox v-model="checkbox_remember_me" class="mb-3">{{$t('Remember Me')}}</vs-checkbox>
                    <router-link to="">{{$t('Forgot Password?')}}</router-link>
                  </div> -->
                  <!-- <vs-button type="border" :to="{name:'Register'}">{{$t('Register')}}</vs-button> -->
                </div>
          <div id="logindiv" class="text-center"> <vs-button size="" @click="login" class="">{{ $t("Login") }}</vs-button></div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  import i18n from '../../../layouts/components/navbar/components/I18n'
  export default {
    components: {
      i18n
    },
    data() {
      return {
        email: '',
        password: '',
        checkbox_remember_me: false,
        ValidationErrors: {
          email: '',
          password: ''
        }
      }
    },
    created() {
      // this.$http.get('sanctum/csrf-cookie')
    },
    computed: {
      userNameAndPasswordComputed: function () {
        return this.email
      }
    },
    methods: {
      userNameAndPassword(password, email) {
        return password
      },
      login() {
        this.ValidationErrors = {
            email: '',
            password: ''
          },
          this.$store.dispatch('auth/LoginAction', {
            email: this.email,
            password: this.password,
            remember_me: this.checkbox_remember_me,
            device_name: this.get_browser_name(),
            lang:localStorage.getItem('locale') ==='tr' ? 'Turkish' : 'English'
          }).then((response) => {
            this.$router.push({
              name: 'Accounts'
            })
          }).catch((error) => {
            if (error.response && error.response.data && error.response.data.errors) {
      this.ValidationErrors.email = error.response.data.errors.email ? error.response.data.errors.email[0] : '';
      this.ValidationErrors.password = error.response.data.errors.password ? error.response.data.errors.password[0] : '';
    }
          })
      },
      social_login(provider) {
        this.$store.dispatch('auth/SocialLoginAction', provider).then((response) => {
          if (response.data.url) {
            window.location.href = response.data.url
          }
        })
      },
      get_browser_name() {
        var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        var isFirefox = typeof InstallTrigger !== 'undefined';
        var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
        var isIE = /*@cc_on!@*/ false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
        var output = 'Blink'
        if (isFirefox)
          output += ' Firefox '
        if (isChrome)
          output += ' Chrome '
        if (isSafari)
          output += ' Safari '
        if (isOpera)
          output += ' Opera '
        if (isIE)
          output += ' IE '
        if (isEdge)
          output += ' Edge '
        if (isEdgeChromium)
          output += ' EdgeChromium '
        return output
      }
    }
  }

</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    button {
      margin: 10px;
    }
  }

  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }

    .bg-twitter {
      background-color: #00aaff;
    }

    .bg-google {
      background-color: #4285f4;
    }

    .bg-github {
      background-color: #333;
    }
  }
}
#logindiv button{
  width: 200px;
}
</style>
